<template>
  <CCol lg="12">
    <CCard>
      <CCardHeader class="bg-yellow"
        ><h3>แบบอนุญาตให้นักเรียนออกนอกสถานศึกษา</h3></CCardHeader
      >
      <form id="formCreateExit" v-on:submit.prevent="sendData" method="POST">
        <CCardBody>
          <CRow>
            <CCol lg="4">
              <div class="relative w-full mb-3" v-if="userData">
                <label
                  class="block uppercase text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  รหัสผู้อนุญาต
                </label>
                <input
                  type="text"
                  name="userId"
                  class="form-control"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  readonly
                  v-model="userData.user_id"
                />
              </div>
            </CCol>
            <CCol lg="4">
              <div class="relative w-full mb-3" v-if="userData">
                <label
                  class="block uppercase text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  รหัสโรงเรียน
                </label>
                <input
                  type="text"
                  name="schoolId"
                  class="form-control"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  readonly
                  required
                  v-model="$route.query.SchoolID"
                />
              </div>
            </CCol>
            <CCol lg="4">
              <div class="relative w-full mb-3" v-if="userData">
                <label
                  class="block uppercase text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  รหัสนักเรียนที่ขออนุญาต
                </label>
                <input
                  type="text"
                  name="stdId"
                  class="form-control"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  readonly
                  required
                  v-model="$route.query.StudentID"
                />
              </div>
            </CCol>
            <CCol lg="12" class="text-right">
              <button type="submit" class="btn btn-warning">ส่ง</button>
            </CCol>
          </CRow>
        </CCardBody>
      </form>
    </CCard>
  </CCol>
</template>

<script>
import MainChartExample from "./charts/MainChartExample";
import WidgetsDropdown from "./widgets/WidgetsDropdown";
import WidgetsBrand from "./widgets/WidgetsBrand";
import WidgetWorkFromHome from "./widgets/WidgetWorkFromHome";
import WidgetFlag from "./widgets/WidgetFlag.vue";

export default {
  name: "Dashboard",
  components: {
    MainChartExample,
    WidgetsDropdown,
    WidgetsBrand,
    WidgetWorkFromHome,
    WidgetFlag,
  },
  data() {
    return {
      selected: "Month",

      userData: this.$cookies.get("user"),
    };
  },
  mounted() {
    //console.log(this.userData);
    if (this.userData == null) {
      alert("please login");
      this.$router.push({ path: "/pages/login" });
    }
  },
  methods: {
    sendData(event) {
      const form = document.getElementById(event.target.id);
      const formData = new FormData(form);
      formData.append("txtAction", "createExit");
      this.axios
        .post(this.$hostUrl + "php_action/exitAPI.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.createState == true) {
            alert("บันทึกสำเร็จ");
            location.reload();
          } else {
            this.responseContent = response.data.createState;
            alert(this.responseContent);
          }
        })
        .finally(() => {});
    },
  },
};
</script>
